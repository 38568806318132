import React, { useEffect, useState } from "react";
import Footer from "./Footer.jsx";
import UnitasLogo from "./UnitasLogo.jsx";

import "./Stats.css";

const ServiceMetricsGrid = () => {
  const [headers, setHeaders] = useState([]);
  const [rowData, setRowData] = useState([]);

  useEffect(function fetchData() {
    fetch("https://nio-nis-api.nexus.unitasglobal.net/api/metrics/network")
      .then((resp) => resp.json())
      .then((json) => {
        setHeaders(Object.keys(json));
        setRowData(Object.values(json));
      })
      .catch(() => {
        alert("error fetching data");
      });
  }, []);

  return (
    <table className="service-metrics-grid">
      <thead>
        <tr className="service-metrics-grid__row service-metrics-grid__row--header">
          <th className="service-metrics-grid__keystone"></th>
          {headers.map((header) => (
            <GridHeader key={header} label={header} />
          ))}
        </tr>
      </thead>
      <tbody>
        {rowData.map((row, i) => (
          <GridRow key={`row_${i}`} headers={headers} row={row} index={i} />
        ))}
      </tbody>
    </table>
  );
};

const GridHeader = ({ label }) => (
  <th className="service-metrics-grid__header">{label}</th>
);

const GridRow = ({ headers = [], row, index }) => {
  const start = headers[index];

  const RowCells = [];
  for (
    let rowIndex = 0, colIndex = 0, length = headers.length;
    colIndex < length;
    colIndex++
  ) {
    const misMatch =
      rowIndex !== length && row[rowIndex]?.end_location !== headers[colIndex];
    const key = `${index}-${colIndex}`;

    RowCells.push(
      misMatch ? (
        <td key={key} className="no-hover" />
      ) : (
        <GridCell key={key} {...row[rowIndex]} />
      )
    );

    rowIndex += !misMatch;
  }

  return (
    <tr className="service-metrics-grid__row">
      <td className="service-metrics-grid__row-header">{start}</td>
      {RowCells}
    </tr>
  );
};

const GridCell = ({
  start_location,
  end_location,
  jitter,
  latency,
  packet_loss,
}) => (
  <td className="service-metrics-grid__cell">
    <div className="tooltip expand">
      <div>
        {start_location} ↩ {end_location}
      </div>
      <ul>
        <li>Latency: {latency}</li>
        <li>Jitter: {jitter}</li>
        <li>Packet Loss: {packet_loss}</li>
      </ul>
    </div>
  </td>
);

const StatsPage = () => (
  <div className="page-wrapper">
    <nav>
      <h1>
        <UnitasLogo />
      </h1>
    </nav>
    <div className="service-metrics-page page-content patterned">
      <h2>Unitas Reach™ Network Stats</h2>
      <ServiceMetricsGrid />
    </div>
    <Footer />
  </div>
);

export default StatsPage;
