import React from "react";
import UnitasLogo from "./UnitasLogo.jsx";

const Footer = () => (
  <div style={{ position: "relative" }}>
    <DesktopFooter />
    <MobileFooter />
  </div>
);

// Fix Notes:
// - Using target="_blank" without rel="noreferrer" is a security risk: see https://html.spec.whatwg.org/multipage/links.html#link-type-noopener
// TODO: - Anchors must have content and the content must be accessible by a screen reader
// - Footer contained two links to `https://unitasglobal.com/blog/`
// - Neither link to the blog was displaing an icon
// - br tags improperly closed

const DesktopFooter = () => (
  <div className="footer large hidden absolute sm:flex left-0 w-screen">
    <div className="links w-3/4 flex items-start">
      <span className="w-40 self-center">
        <UnitasLogo height="48px" />
      </span>
      <ul className="text-white text-sm py-12">
        <li className="mb-2">
          <a href="https://unitasglobal.com/services/">Services</a>
        </li>
        <li>
          <a href="/services/connectivity">Connectivity</a>
        </li>
        <li>
          <a href="/services/cloud-infrastructure/">Cloud Infrastructure</a>
        </li>
        <li>
          <a href="/services/managed-services">Managed Services</a>
        </li>
        <li>
          <a href="/partners">Partners</a>
        </li>
      </ul>
      <ul className="text-white text-sm py-12">
        <li className="mb-2">
          <a href="/outcomes">Outcomes</a>
        </li>
        <li>
          <a href="https://unitasglobal.com/outcomes/accelerate-your-cloud-journey/">
            Accelerate Your Cloud Journey
          </a>
        </li>
        <li>
          <a href="https://unitasglobal.com/outcomes/modernize-your-connectivity/">
            Modernize Your Connectivity
          </a>
        </li>
        <li>
          <a href="https://unitasglobal.com/outcomes/reducing-network-costs/">
            Reducing Network Costs
          </a>
        </li>
        <li>
          <a href="/outcomes/enable-multi-cloud-architecture/">
            Cloud-Enable Your Infrastructure
          </a>
        </li>
      </ul>
      <ul className="text-white text-sm py-12">
        <li className="mb-2">
          <a href="https://unitasglobal.com/about-us/">About Us</a>
        </li>
        <li>
          <a href="https://unitasglobal.com/about-us/">Why Unitas</a>
        </li>
        <li>
          <a href="https://unitasglobal.com/resources/client-success-case-studies/">
            Customer Stories
          </a>
        </li>
        <li>
          <a href="https://unitasglobal.com/about-us/news-and-events/">
            In The News
          </a>
        </li>
        <li>
          <a href="https://unitasglobal.com/blog/">Blog</a>
        </li>
        <li>
          <a href="https://unitasglobal.com/about-us/careers/">Careers</a>
        </li>
        <li>
          <a href="https://unitasglobal.com/about-us/leadership/">Leadership</a>
        </li>
        <li>
          <a href="https://unitasglobal.com/contact-us/">Contact Us</a>
        </li>
      </ul>
    </div>

    <div className="contact flex w-1/4 items-start justify-start bg-brand-black">
      <div className="flex flex-col w-3/5 lg:w-1/2 mx-auto content-between py-12">
        <div className="text-white mt-4 flex w-2/3 justify-between">
          <a
            href="https://www.linkedin.com/company/unitas-global/"
            target="_blank"
            rel="noreferrer"
            title="Visit us at LinkedIn"
          >
            <i className="fab fa-linkedin-in" aria-hidden="true"></i>
          </a>
          <a
            href="https://twitter.com/unitasglobal"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-twitter" aria-hidden="true"></i>
          </a>
          <a
            href="https://unitasglobal.com/blog/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fas fa-blog" aria-hidden="true"></i>
          </a>
        </div>
        <p className="text-white text-xs pb-4">
          <br />
          +1 844 864 8271
          <br />
          910 W Van Buren St Suite 610
          <br />
          Chicago, IL 60607
          <br />
          <br />
          <a href="/website-privacy-policy/">Privacy Policy</a>
          &nbsp;|&nbsp;
          <a href="/acceptable-use-policy/">Acceptable Use Policy</a>
          &nbsp;|&nbsp;
          <a href="https://unitasglobal.com/unitas-global-supplier-data-security-addendum/">
            Supplier Data Security Addendum
          </a>
          &nbsp;|&nbsp;
          <a href="https://unitasglobal.com/unitas-global-data-security-addendum-page/">
            Data Security Addendum
          </a>
          <br />
          Copyright 2021 © All rights Reserved.
        </p>
      </div>
    </div>
  </div>
);

const MobileFooter = () => (
  <div className="footer mobile w-full sm:hidden text-center bg-brand-black font-bold">
    <div className="flex flex-col w-1/2 mx-auto">
      <ul className="py-8">
        <li className="py-1">
          <a href="https://unitasglobal.com/services/">Services</a>
        </li>
        <li className="py-1">
          <a href="/outcomes">Outcomes</a>
        </li>
        <li className="py-1">
          <a href="https://unitasglobal.com/about-us/">About Us</a>
        </li>
      </ul>
      <div>
        <p className="text-xs pb-4">
          <a href="/website-privacy-policy/">Privacy Policy</a>
          &nbsp;|&nbsp;
          <a href="/acceptable-use-policy/">Acceptable Use Policy</a>
          &nbsp;|&nbsp;
          <a href="https://unitasglobal.com/unitas-global-data-security-addendum-page/">
            Data Security Addendum
          </a>
          <br />
          Copyright 2021 © All rights Reserved.
        </p>
      </div>
    </div>
  </div>
);

export default Footer;
